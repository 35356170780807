import request from '@/utils/request'
import qs from 'qs'

/*
*   获取寺庙对象
*  */
export const getProductById = (productId) => {
  return request.get('/product/getProductById', {
    params: {
      productId
    }
  }).catch(r=>{
    console.log(r)
  })
}


/*
* 哆啦宝微信支付
*  */
export const DLBWXPayProductQr = (obj) => {
  // 将对象转换为 JSON 字符串
  const data = JSON.stringify({
    productId: obj.productId,
    templeId: obj.templeId,
    productName: obj.productName,
    openId: obj.openId,
    orderPrice: obj.orderPrice,
    payType: obj.payType,
    notes: obj.notes
  })
  // 发送 POST 请求，将 JSON 字符串作为请求主体
  console.log(data)
  return request.post('/product/DLBWXPay', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).catch(r =>{
    alert(r)
  })
}

/*
* 哆啦宝支付宝认证
*  */
export const DLBPayAuthorizationProductQr = (obj) =>{
  const data  = JSON.stringify({
    productId: obj.productId,
    templeId: obj.templeId,
    productName: obj.productName,
    openId: obj.openId,
    orderPrice: obj.orderPrice,
    payType: obj.payType,
    notes: obj.notes
  })
  return request.post('/product/authorization', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).catch(r =>{
    alert(r)
  })
}


/*
* 哆啦宝支付宝支付
*
*  */
export const DLBZFBPayProductQr = (obj) => {
  //将对象转换为JSON字符串
  const data  = qs.stringify({
    state: obj.state,
    auth_code: obj.auth_code
  })
  return request.post('/product/DLBZFBPay', data).catch(r =>{
    alert(r)
  })

}
