<template>
  <div class="bless-container">
    <!--输入框-->
    <div class="pray-container">
      <div class="content-box">
        <van-cell-group class="cell-group" :border="false">

          <van-popover
            v-model="historyShow"
            trigger="click"
            :actions="actions"
            placement="top"
            @select="onSelectPop"
          >
            <template #reference>
              <van-field
                v-model="prayName"
                label="祈福愿者:"
                placeholder="请输入祈福愿者"
                :border="false"
                maxlength="12"
                @input="handleInput"
                @blur="$store.commit('setPrayName',prayName)"
                ref="prayname_field"
              ></van-field>
            </template>
          </van-popover>

<!--          <van-field-->
<!--            v-model="prayName"-->
<!--            label="祈福愿者:"-->
<!--            placeholder="请输入祈福愿者"-->
<!--            :border="false"-->
<!--            maxlength="12"-->
<!--            @input="handleInput"-->
<!--            @blur="$store.commit('setPrayName',prayName)"-->
<!--            ref="prayname_field"-->
<!--          ></van-field>-->
          <van-field
            v-model="prayComment"
            label="祈福心愿:"
            placeholder="请输入祈福心愿"
            readonly
            clickable
            :border="false"
            right-icon="arrow"
            @click="blessShow = true"
          />
        </van-cell-group>
      </div>
    </div>
    <!--        祈福心愿列表-->
    <van-action-sheet
      v-model="blessShow"
      :closeable="true"
      class="bless-content-box"
    >
      <div class="bless-item-box">
        <div
          v-for="(item, index) in prayList"
          :key="index"
          @click="onSelect(item)"
          class="bless-content"
          :class="prayTemp === item ? 'bless-choose' : 'no-bless-choose'"
        >
          {{ item }}
        </div>
      </div>

      <div class="col-flex">
        <div class="confirm" @click="confirmBless">确定</div>
        <div class="cancel" @click="cancelBless">取消</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { mapMutations, mapState } from 'vuex'

export default {
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    prayList: Array
  },
  // 这里用于存放数据
  data () {
    return {
      prayComment: '身体健康万事如意',
      prayName: this.$store.state.prayName || '',
      prayTemp: '身体健康万事如意',
      blessShow: false,
      historyShow: false,
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('local',['historyList']),
    actions() {
      return this.historyList || [];  //{ text: '选项一' }, { text: '选项二' }, { text: '选项三' }
    },

  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    ...mapMutations(['setPrayName']),
    handleInput(event) {
      const pattern = /^[\u4e00-\u9fa5a-zA-Z0-9,.]{0,12}$/;
      if (!pattern.test(event)) {
        // 如果输入的内容不符合规定的范围，则删除当前输入的内容
        this.prayName = event.slice(0, -1);
        this.$toast('抱歉,占不支持特殊符号!')
      } else {
        this.prayName = event;
      }
    },
    onSelect (item) {
      this.prayTemp = item
    },
    confirmBless () {
      this.prayComment = this.prayTemp
      this.$store.commit('setPrayComment',this.prayTemp)
      this.blessShow = false
    },
    cancelBless () {
      this.blessShow = false
    },
    focusInput() {
      this.$refs.prayname_field.focus(); // 聚焦输入框
    },
    onSelectPop(action){
      this.prayName = action.text;
      this.setPrayName(action.text);
    },
  },

  directives:{
    // 在组件中定义自动聚焦指令
    // 当被绑定的元素插入到 DOM 中时触发
    focus: {
      // 当被绑定的元素插入到 DOM 中时触发
      inserted: function (el) {
        this.$nextTick(() => {
          el.focus(); // 聚焦到输入框
        });
      }
    }
  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.bless-container {
  .pray-container {
    padding: 0 15px;

    .content-box {
      width: 345px;
      height: 120px;

      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */

      .cell-group {
        width: 100%; /* 宽度撑满父级容器 */
        height: 95%; /* 高度撑满父级容器 */
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: #fbf1ee !important;
        border-radius: 10px;
        box-sizing: border-box;
        .van-cell {
          background: #fbf1ee;
          :deep .van-cell__title {
            font-size: 17px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.bless-content-box {
  padding: 9px;
  box-sizing: border-box;
  font-size: 16px;

  .bless-item-box {
    height: 40vh;
    overflow-y: scroll;

    .bless-content {
      width: 325px;
      height: 44px;
      box-sizing: border-box;
      border-radius: 6px;
      margin: 10px;
      line-height: 44px;
      padding: 0 18px;
      text-align: center;
    }
  }

  .col-flex {
    height: 94px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 15px;

    .confirm {
      width: 325px;
      height: 42px;
      line-height: 42px;
      background: #A92B2A;
      border-radius: 21px;
      text-align: center;

      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC, sans-serif;
      font-weight: 500;
      color: #ffffff;
      letter-spacing: 1px;
    }

    .cancel {
      width: 325px;
      height: 42px;
      border-radius: 21px;
      border: 1px solid #e0e6e9;
      text-align: center;
      line-height: 42px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC, sans-serif;
      font-weight: 500;
      color: #999999;
      letter-spacing: 1px;
    }
  }
}

.bless-choose {
  border: 2px solid #b34341;
  background-color: #e5ddd0;
}

.no-bless-choose {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

/deep/ .van-popover .van-popover__arrow {
  display: none;
}

.van-popover__wrapper {
  width: 100%;
}

.van-cell__value--alone {
  text-align: center;
}

.van-cell--clickable {
  background: #fbf1ee;
}
:deep .van-field__body{
  font-size: 16px;
}


</style>
