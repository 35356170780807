<template>
  <div class="singal-container">
    <div class="ul-singn" @click="signalNumShow = !signalNumShow">
      <div v-for="(imgSrc, index) in SIGNAL_LIST" :key="index" :style="{
                  width: '4px',
                  height: 4 * index + 'px',
                  'background-color': calcSIGNAL(JSON.parse(`${finalDeviceSignal}`), index),
                  'border-right': '1px solid #aaa',
                }"></div>
      <div class="no-singn" v-if="JSON.parse(`${finalDeviceSignal}`) === 0">|
      </div>

    </div>
    <div class="signal-info" v-if="signalNumShow" :class="getSignalClass(finalDeviceSignal)">
            当前信号: {{ getStatusInfo(finalDeviceSignal)}}
    </div>

  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
export default {
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    deviceSignal: {
      type: Number,
      default: 9
    },
  },
  // 这里用于存放数据
  data() {
    return {
      SIGNAL_LIST: [1, 2, 3, 4, 5, 6],
      signalNumShow: false
    }
  },
//  计算属性 类似于data概念
  computed: {
    finalDeviceSignal(){
      if(this.deviceSignal){
        if(this.deviceSignal < 13){
          this.signalNumShow = true
        }
        return this.deviceSignal
      }else{
        return  +this.$store.state.device.device.deviceSignal || 0
      }
    }
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    // 心灯信号图标显示
    calcSIGNAL(signal, index) {
      if (signal >= 26 && index <= 5) {
        return "#333";
        // `backgroundColor": (backgroundColor = "#333")`
        // index
      } else if (signal >= 21 && index <= 4) {
        return "#333";
        //  index
      } else if (signal >= 17 && index <= 3) {
        return "#333";
        // index
      } else if (signal >= 13 && index <= 2) {
        return "#333";
        // index
      } else if (signal >= 6 && index <= 1) {
        return "#333";
      } else {
        return "#ccc";
        // index
      }
    },
    getStatusInfo(signal){
        if(signal >=21){
          return "强"
        }else if(signal >= 13 && signal < 21){
          return "一般"
        }else if(signal >= 6 && signal < 13){
          return "弱"
        }else{
          return "离线"
        }
    },
    getSignalClass(signal){
      if (signal >= 21) {
        return 'strong-signal';
      } else if (signal >= 13 && signal < 21) {
        return 'medium-signal';
      } else if (signal >= 6 && signal < 13) {
        return 'weak-signal';
      } else {
        return 'offline-signal';
      }
    }
  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created() {
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted() {
  },
//  生命周期 - 创建之前
  beforeCreate() {
  },
//  生命周期 - 挂载之前
  beforeMount() {
  },
//  生命周期 - 更新之前
  beforeUpdate() {
  },
//  生命周期 - 更新之后
  updated() {
  },
//  生命周期 - 销毁之前
  beforeDestroy() {
  },
//  生命周期 - 销毁完成
  destroyed() {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated() {
  }

}
</script>

<style lang="less" scoped>
.singal-container {
  display: flex;
  flex-direction: column;
  .ul-singn {
    margin-left: auto;
    margin-top: 5px;
    margin-right: 15px;
    position: relative;
    display: flex;
    width: 40px;
    height: 30px;
    justify-content: center;
    align-items: flex-end;
    line-height: 30px;
    padding-bottom: 7px;
    box-sizing: border-box;
  }

  .no-singn {
    position: absolute;
    left: 45%;
    top: 0;
    font-size: 30px;
    transform: rotate(-45deg);
    color: #aaa;
  }
  .signal-info{
    font-size: 10px;
    margin-left: auto;
    margin-right: 5px;
  }

  .strong-signal {
    color: darkgreen;
  }

  .medium-signal {
    color: greenyellow;
  }

  .weak-signal {
    color: orange;
  }

  .offline-signal {
    color: gray;
  }

}
</style>
