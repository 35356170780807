import request from '@/utils/request'

/*
* 根据寺庙id获取设备列表
*
* */
export const initData = (IMEIID)=>{
  return request.get('/collection/initialization',{
    params:{
      IMEIID
    }
  }).catch(r=>{
    console.log(r)
  })
}
