<template>
  <div class="devicelight-content">
    <div class="banner-box">
      <BannerSwipe/>
    </div>
    <div class="signal-box">
      <SignalLine :device-signal="+device.deviceSignal"/>
    </div>
    <div class="content-box">
      <!--    点灯祈福-->
      <div class="line-box">
        <CrossLine title="点灯祈福"/>
      </div>
      <!-- 祈福愿者 祈福语句   -->
      <div>
        <LuckDream :prayList="prayList" ref="luckDreamRef"  />
      </div>
      <!--套餐&心灯选择-->
      <div class="line-box">
        <CrossLine title="套餐&心灯选择"/>
      </div>
      <div>
        <MenuAndLight @changeComboShow="comboShow = true" @changeLightShow="DeviceStatusShow = true"/>
      </div>
    </div>
    <div>
      <ComboSheet :comboShow="comboShow" @changeComboShow="comboShow = false"/>
    </div>
    <div>
      <DeviceStatus :selectCommit="true" :show="DeviceStatusShow" :deviceStatusMap="device.deviceStatusMap"
                    @closeAction="DeviceStatusShow = false" />
    </div>
    <div class="bottom-box" v-show="isPayShow">
      <Pay @changeDeviceShow="DeviceStatusShow = true" />
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import BannerSwipe from '@/components/Banner/BannerSwipe.vue'
import CrossLine from '@/components/common/CrossLine.vue'
import SignalLine from '@/components/common/SignalLine.vue'
import LuckDream from '@/components/DeviceLight/LuckDream.vue'
import MenuAndLight from '@/components/DeviceLight/MenuAndLight.vue'
import DeviceStatus from '@/components/DeviceList/DeviceStatus.vue'
import ComboSheet from '@/components/DeviceLight/ComboSheet.vue'
import Pay from '@/components/Pay/Pay.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { initData } from '@/api/modules/init'
import { getQueryParam } from '@/utils/url'
import { getPrayList } from '@/api/modules/pray'
export default {
  name: 'DeviceLightIndex',
  // import 引入的组件需要注入到对象中才能使用
  components: {
    Pay,
    ComboSheet,
    DeviceStatus,
    MenuAndLight,
    LuckDream,
    SignalLine,
    CrossLine,
    BannerSwipe
  },

  props: {},
  // 这里用于存放数据
  data () {
    return {
      comboShow: false,
      DeviceStatusShow: false,
      IMEIID: getQueryParam('IMEIID') || device.deviceImei || '',
      originalHeight: 0, //屏幕原始高度
      screenHeight: 0, //屏幕实时高度
      isPayShow: true,
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('device', ['device']),
    ...mapState('pray', ['prayList']),
    ...mapState('combo', ['comboList']),
    ...mapGetters('temple', ['getTempleId', 'getTempleName']),
  },
//  监控data 中数据变化
  watch: {
    //监听屏幕高度变化
    screenHeight(newHeight) {
      // console.log(newHeight,111111)
      // console.log(this.originalHeight,22222222)
      // console.log(this.originalHeight <= newHeight)
      // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      // if(!isIOS){
      //
      // }
      this.isPayShow = this.originalHeight <= newHeight;  //原始高度小于等于实时高度
    }


  },
//  方法集合
  methods: {
    ...mapMutations('temple', ['setTempleData']),
    ...mapMutations('device', ['setDevice']),
    ...mapMutations('pray', ['setPrayList']),
    ...mapMutations('combo', ['setComboList']),

    async Initialization () {
      const { data: { data } } = await initData(this.IMEIID)
      this.setTempleData(data.templeInfoFuture)
      this.setComboList(data.comboListFuture)
      //this.setPrayList(data.prayListFuture)
      this.setDevice(data.deviceDataFuture)
      //更新标题
      document.title = data.templeInfoFuture.templeName
      this.$store.state.deviceType = data.deviceDataFuture.deviceType
    },
    async getPrayList(){
      if(this.prayList.length === 0){
        const res = await getPrayList(this.IMEIID)
        this.setPrayList(res.data.data)
      }
    },
    // 获取设备状态
    async getDeviceStauts(){

    },
    previewFn (value) {
      this.DeviceStatusShow = value
    },
    watchResize() {
      // this.screenHeight = document.documentElement.clientHeight;
      this.screenHeight = this.getHeight();
    },
   getHeight() {
  // 获取屏幕高度，兼容不同浏览器
     const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
     // 在微信中，由于地址栏和底部菜单栏的存在，可能需要进行调整
  // 通过微信内置对象获取更准确的高度
  if (typeof window.WeixinJSBridge === 'object' && typeof window.WeixinJSBridge.invoke === 'function') {
    window.WeixinJSBridge.invoke('getNetworkType', {}, function (res) {
      // 在这个回调函数中，可以获取更准确的高度信息
      // res.err_msg中包含了设备的信息，例如 screen_height
      const screenInfo = res.err_msg
      const matchResult = screenInfo.match(/screen_height:(\d+)/)
      if (matchResult && matchResult[1]) {
        const accurateHeight = parseInt(matchResult[1])
        //console.log("准确的屏幕高度：" + accurateHeight);
      }
    });
  }
  // 返回一般的屏幕高度
  //console.log("屏幕高度：" + screenHeight);
  return screenHeight;
}
  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
    this.Initialization()
    this.getPrayList()
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
    //获取首次进入页面的原始高度
    // this.originalHeight = document.documentElement.clientHeight;
    //this.originalHeight = this.getHeight();
    setTimeout(() => {
      this.originalHeight = this.getHeight();
    }, 50);
    //监听页面高度实时变化
    window.addEventListener('resize', this.watchResize);
  },

//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
    // 销毁
    window.removeEventListener("resize", this.watchResize);
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.devicelight-content {
  overflow-y: scroll;
  overscroll-behavior: contain; /* 禁止滚动溢出 */
  -webkit-overflow-scrolling: touch;

  .banner-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #ffffff;
  }
  .signal-box {
    position: fixed;
    top: 174px;
    left: 0;
    right: 0;
    z-index: 1;
    background: #ffffff;
    width: 100vw;
    height: 30px;
  }

  .content-box{
    margin-top: 174px + 40px;
  }
  .bottom-box{
    min-height: 100px;
  }
}


</style>
