//微信浏览器调起微信支付
function WXPAY(params, callback) {
  if (typeof WeixinJSBridge === "undefined") {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady(params, callback), false);
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', onBridgeReady(params, callback));
      document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(params, callback));
    }
  } else {
    onBridgeReady(params, callback);
  }
}

function onBridgeReady(params, callback) {
  const that = this
  WeixinJSBridge.invoke(
    'getBrandWCPayRequest', {
      "appId": params.appId,
      "timeStamp": params.timeStamp,
      "nonceStr": params.nonceStr,
      "package": params.package,
      "signType": params.signType,
      "paySign": params.paySign
    },
    function (res) {
      callback(res)
    }
  ).then(r => {
    console.log(r)
  });
}
export default WXPAY
