import request from '@/utils/request'
import qs from 'qs'

/*
* 官方微信支付
*  */
export const GFWXPay = (obj) => {
  // 将对象转换为 JSON 字符串
  const data = JSON.stringify({
    IMEIID: obj.IMEIID,
    comboId: obj.comboId,
    prayName: obj.prayName,
    prayComment: obj.prayComment,
    portNumber: obj.portNumber,
    openId: obj.openId,
    orderPrice: obj.orderPrice
  })
  // 发送 POST 请求，将 JSON 字符串作为请求主体
  return request.post('/pay/Wechatpay', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).catch(r =>{
    alert(r)
  })
}

/*
* 哆啦宝微信支付
*  */
export const DLBWXPay = (obj) => {
  // 将对象转换为 JSON 字符串
  const data = JSON.stringify({
    IMEIID: obj.IMEIID,
    comboId: obj.comboId,
    prayName: obj.prayName,
    prayComment: obj.prayComment,
    portNumber: obj.portNumber,
    openId: obj.openId,
    orderPrice: obj.orderPrice
  })
  // 发送 POST 请求，将 JSON 字符串作为请求主体
  return request.post('/dlbpay/DLBWXPay', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).catch(r =>{
    alert(r)
  })
}

/*
* 官方支付宝支付
*  */
export const GFZFBPay = (obj) => {
  // 将对象转换为 JSON 字符串
  const data = JSON.stringify({
    IMEIID: obj.IMEIID,
    comboId: obj.comboId,
    prayName: obj.prayName,
    prayComment: obj.prayComment,
    portNumber: obj.portNumber,
    orderPrice: obj.orderPrice
  })
  // 发送 POST 请求，将 JSON 字符串作为请求主体
  return request.post('/pay/ZfbCrtAliPay', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).catch(r =>{
    alert(r)
  })
}

/*
* 哆啦宝支付宝认证
*  */
export const DLBPayAuthorization = (obj) =>{
  const data  = JSON.stringify({
    IMEIID: obj.IMEIID,
    comboId: obj.comboId,
    prayName: obj.prayName,
    prayComment: obj.prayComment,
    portNumber: obj.portNumber,
    orderPrice: obj.orderPrice
  })
  return request.post('/dlbpay/authorization', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).catch(r =>{
    alert(r)
  })
}


/*
* 哆啦宝支付宝支付
*
*  */
export const DLBZFBPay = (obj) => {
  //将对象转换为JSON字符串
  const data  = qs.stringify({
    state: obj.state,
    auth_code: obj.auth_code
  })
  return request.post('/dlbpay/DLBZFBPay', data).catch(r =>{
    alert(r)
  })

}

